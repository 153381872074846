import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "../Image";
import { P } from "../../components/typo";
import { device } from "../device";

//Text animation
import Reveal from "react-reveal/Reveal";
import "../../animate.css";

//Media
import newLogo from "../../resources/media/new_logo.png";
import Logo from "../../resources/media/Logo2.svg";
import MLogo from "../../resources/media/Logo_mf_red.png";
import Logo90 from "../../resources/media/90_Konto_Logo.svg";
import HandMedLada from "../../resources/media/SVG/handmedlåda_ny.svg";
import HandMedKopp from "../../resources/media/SVG/menskopp-hand.svg";
import HandMedTamp from "../../resources/media/SVG/tampong.svg";
import HanderSomHjarta from "../../resources/media/SVG/händer som hjärta_ny-v2.svg";

const FooterBody = styled.div`
  > * {
    margin: auto;
    max-width: 1440px;
  }
  position: relative;
  display: ${props => (window.innerWidth < device.tablet ? "flex" : "block")};
  height: ${props =>
    props.height || window.innerWidth >= device.laptop
      ? "500px"
      : window.innerWidth >= device.tablet
      ? "640px"
      : "1210px"};
  flex-direction: row-reverse;
  overflow: hidden;
  padding-bottom: -200px;
`;

const FooterInfo = styled.div`
  height: ${props =>
    props.height || window.innerWidth >= device.laptop
      ? "500px"
      : window.innerWidth >= device.tablet
      ? "640px"
      : "1160px"};
  display: ${props => (window.innerWidth < device.tablet ? "block" : "flex")};
  justify-content: ${props =>
    window.innerWidth >= device.tablet ? "end" : "space-between"};
  flex-wrap: wrap;
  margin: ${props => (window.innerWidth >= device.tablet ? "" : "0 0 0 0")};
  padding: ${props =>
    window.innerWidth >= device.laptop
      ? "20px 140px 0 140px"
      : "20px 23px 0 23px"};
  width: ${props => (window.innerWidth >= device.tablet ? "" : "100%")};
  p {
    font-size: 14px;
  }

  div {
    @media ${device.tabletMedia} {
      height: 186px;
      width: 45%;
    }
    @media ${device.laptopMedia} {
      p {
        font-size: 14px;
        margin-top: ${props => props.marginTop || "8px"};
      }
      &:nth-child(1) {
        width: 34%;
      }
      &:nth-child(2) {
        width: 22%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 22%;
      }
    }
  }
`;

const FooterImg = styled.img`
  height: ${props => props.height || "60px"};
  > * {
    margin: auto;
    max-width: 1440px;
  }

  @media ${device.tabletMedia} {
    margin-left: 20px;
  }
  @media ${device.laptopMedia} {
    height: ${props => props.height || "90px"};
    margin-left: 50px;
    /* justify-content: flex-end; */
  }
`;

const Footer = props => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const redirect = link => {
    window.location.replace(link);
  };

  useEffect(() => {
    setInnerWidth(props.innerWidth);
  }, [props.innerWidth]);

  return (
    <>
      <FooterBody>
        <FooterInfo>
          <Reveal effect="animate">
            <div
              className="1"
              style={{
                margin:
                  props.innerWidth >= device.laptop
                    ? "60px 0 0 0"
                    : props.innerWidth < device.laptop &&
                      props.innerWidth >= device.tablet
                    ? "20px 23px 0 23px"
                    : "60px 0 0 0px"
              }}
            >
              <P
                // fontSize="14px"
                margin="50px 0 10px 0"
                fontWeight="bold"
                textAlignM="left"
                textAlign="left"
                text="KONTAKT"
              />
              <P
                // fontSize="14px"
                margin="8px 0 0 0"
                textAlign="left"
                textAlignM="left"
                text="kommunikation@stadsmissionen.org"
              />
              <P
                // fontSize="14px"
                margin="8px 0 0 0"
                textAlign="left"
                textAlignM="left"
                text="031-755 36 00"
              />
            </div>
            <div
              className="2"
              style={{
                margin:
                  props.innerWidth >= device.laptop
                    ? "60px 0 0 23px"
                    :  props.innerWidth >= device.tablet &&
                    props.innerWidth < device.laptop ?
                    "20px 0 0 0px"
                    : "50px 0 0 0px"
              }}
            >
              <P
                // fontSize="14px"
                margin="50px 0 10px 0"
                fontWeight="bold"
                textAlignM="left"
                textAlign="left"
                text="INFORMATION"
              />
              <P
                // fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirect(
                    "https://www.facebook.com/GbgStadsmission/?__tn__=%2Cd%2CP-R&eid=ARBpc-tRxM2YiINxuYvTDgwRhiCS56gHY-2JEYlMr_oGkijR2pRcmx7XcU8PPXGpHXYlR26EVX9v1dOm"
                  );
                }}
                textAlignM="left"
                textAlign="left"
                text="Till stadsmissionen"
              />
              <P
                // fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirect("https://www.instagram.com/gbgstadsmission/");
                }}
                textAlignM="left"
                textAlign="left"
                text="Personuppgifter"
              />
              <P
                // fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirect("https://www.instagram.com/gbgstadsmission/");
                }}
                textAlignM="left"
                textAlign="left"
                text="Cookies"
              />
            </div>
            <div
              className="3"
              style={{
                margin:
                  props.innerWidth >= device.laptop
                    ? "60px 0 0 0"
                    : props.innerWidth >= device.tablet
                    ? "00px 0 0 23px"
                    : "20px 0 0 0px"
              }}
            >
              <P
                // fontSize="14px"
                margin="50px 0 10px 0"
                fontWeight="bold"
                textAlignM="left"
                textAlign="left"
                text="FÖLJ OSS PÅ"
              />
              <P
                // fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirect(
                    "https://www.facebook.com/GbgStadsmission/?__tn__=%2Cd%2CP-R&eid=ARBpc-tRxM2YiINxuYvTDgwRhiCS56gHY-2JEYlMr_oGkijR2pRcmx7XcU8PPXGpHXYlR26EVX9v1dOm"
                  );
                }}
                textAlignM="left"
                textAlign="left"
                text="Facebook"
              />
              <P
                // fontSize="14px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirect("https://www.instagram.com/gbgstadsmission/");
                }}
                textAlignM="left"
                textAlign="left"
                text="Instagram"
              />
            </div>
            <div
              className="4"
              style={{
                margin:
                  props.innerWidth >= device.laptop
                    ? "60px 0px 0 0"
                    : props.innerWidth >= device.tablet
                    ? "0px 0 0 23px"
                    : "20px 0 0 0px"
              }}
            >
              <P
                // fontSize="14px"
                marginTopM="30px"
                margin="50px 0 10px 0"
                fontWeight="bold"
                textAlignM="left"
                textAlign="left"
                text="BEHÖVER DU HJÄLP?"
              />
              <P
                // fontSize="14px"
                textAlignM="left"
                textAlign="left"
                text="Kontakta oss på"
              />
              <P
                // fontSize="14px"
                textAlignM="left"
                textAlign="left"
                text="infodrottninggatan@stadsmissionen.org"
              />
            </div>
            <div
              style={{
                margin:
                  props.innerWidth >= device.tablet
                    ? "62px 0px 0 23px"
                    : "48px 23px 0 23px",
                display: "flex",
                alignItems: "center",
                width: props.innerWidth >= device.tablet ? "100%" : "80%",
                maxWidth: "1440px",
                justifyContent:
                  props.innerWidth >= device.tablet
                    ? props.innerWidth >= device.laptop
                      ? "flex-end"
                      : ""
                    : "space-between",
                flexDirection:
                  props.innerWidth >= device.tablet &&
                  props.innerWidth < device.laptop
                    ? "row-reverse"
                    : "",
                position:
                  props.innerWidth >= device.tablet ? "unset" : "absolute",
                bottom: innerWidth >= device.tablet ? "40px" : "",
                right: innerWidth >= device.tablet ? "140px" : ""
              }}
            >
              <FooterImg src={MLogo} alt="" />
              <FooterImg src={newLogo} alt="" />
              <FooterImg src={Logo90} height="30px" alt="" />
            </div>
          </Reveal>
        </FooterInfo>
        <>
          {innerWidth < device.tablet && (
            <Image
              display="block"
              position="absolute"
              left="-50px"
              zIndex="9"
              bottom="240px"
              src={HandMedLada}
              transform="rotate(16deg)"
              imgWidth="200px"
              alt=""
            />
          )}
          {/* {innerWidth > device.laptop && (
            <Image
              display="table"
              position="absolute"
              right="0"
              bottom="-10px"
              zIndex="9"
              marginLeft="-44%"
              marginBottom="-150px"
              src={HandMedTamp}
              imgWidth="350px"
              objectFit="initial"
              transform="rotate(10deg)"
            />
          )} */}
          {innerWidth < device.tablet && (
            <Image
              display="table"
              position="absolute"
              // right="15%"
              bottom="-10px"
              zIndex="9"
              // marginLeft="10%"
              marginBottom="-6px"
              src={HandMedTamp}
              imgWidth="250px"
              objectFit="initial"
              transform="rotate(6deg)"
              alt=""
            />
          )}

          {innerWidth >= device.tablet && innerWidth < device.laptop && (
            <Image
              display="table"
              position="absolute"
              left="-120px"
              bottom="-4%"
              zIndex="-1"
              // marginLeft="-10%"
              marginBottom="30px"
              transform="rotate(20deg)"
              src={HanderSomHjarta}
              imgWidth="700px"
              objectFit="initial"
              alt=""
            />
          )}
          {innerWidth >= device.laptop && (
            <Image
              display="table"
              position="absolute"
              left="-100px"
              bottom="-8%"
              zIndex="-1"
              // marginLeft="-10%"
              marginBottom="30px"
              transform="rotate(26deg)"
              src={HanderSomHjarta}
              imgWidth="700px"
              objectFit="initial"
              alt=""
            />
          )}
          {innerWidth < device.tablet && (
            <Image
              display="table"
              position="absolute"
              left="-30px"
              bottom="1%"
              zIndex="9"
              // marginLeft="-10%"
              marginBottom="-20px"
              transform="rotate(10deg)"
              src={HanderSomHjarta}
              imgWidth="500px"
              objectFit="initial"
              alt=""
            />
          )}
          {innerWidth < device.tablet && (
            <Image
              display="table"
              position="absolute"
              right="-50px"
              bottom="-10px"
              zIndex="9"
              // marginLeft="-10%"
              marginBottom="150px"
              src={HandMedKopp}
              imgWidth="120px"
              objectFit="initial"
              transform="rotate(-26deg)"
            />
          )}
        </>
      </FooterBody>
    </>
  );
};

export default Footer;
