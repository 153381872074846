import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "../../components/Image";

import Logo from "../../resources/media/Logo2.svg";

const HeaderMobileBody = styled.div`
  z-index: 999;
  display: fixed;
  position: fixed;
  top: 0;
  width: ${props => props.width || "100vw"};
  height: ${props => props.height || "247px"};
  background-color: ${props => props.BG};
`;

const Link = styled.a`
  position: absolute;
  margin-top: ${props => props.marginTop};
  text-align: center;
  top: ${props => props.top};
  padding-top: ${props => props.paddingTop || "0px"} ;
  /* padding-left: 23px; */
  font-size: 14px;
  width: 102px;
  color: #111111;
  letter-spacing: 0.36px;
  margin: ${props => props.margin || "14px 0 0 0"};
  font-weight: 700;
  text-decoration: none;
  width: 100%;
`;

const OpenBurger = styled.div`
  opacity: 0;


  &.show {
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
    opacity: 1;
    top: 0;
    width: 100vw;
    padding-top: 4px;
    /* display: grid; */
    transition: background-color 0s ease;
    background-color: rgba(254, 204, 216, 1);
  }
`;

const Burger = styled.div`
  z-index: 999;
  right: 26.5px;
  top: 24px;
  display: flow-root;
  position: absolute;
  .bar1,
  .bar2 {
    margin: 8px 0;
    transition: 0.3s;
    width: 30px;
    height: 3px;
    background: #111;
  }

  .bar2 {
    &.change {
      transform: rotate(-45deg) translate(-0px, -0px);
    }
  }
  .bar1 {
    &.change {
      transform: rotate(45deg) translate(-7px, -8px);
    }
  }
`;

const HeaderMobile = props => {
  const [open, setOpen] = useState(false);

  useEffect(() => {}, [open]);

  return (
    <HeaderMobileBody {...props}>
      <Burger onClick={e => setOpen(!open)}>
        <div className={open ? "change bar2" : "bar2"} />
        <div className={open ? "change bar1" : "bar1"} />
      </Burger>
      {/* {open && ( */}
      <OpenBurger className={open ? "show" : ""}>
        <Image position="relative" margin="18px auto 0 auto" transform="scale(0.9)" src={Logo} />
        <Link
          onClick={e => setOpen(!open)}
          href="#videoSecction"
          top="128px"
        >
          MENSMANIFEST
        </Link>
        <Link
          onClick={e => setOpen(!open)}
          href="#problemSection"
          paddingTop="0px"
          top="170px"
        >
          OM PROJEKTET
        </Link>
      </OpenBurger>
      {/* )} */}
    </HeaderMobileBody>
  );
};

export default HeaderMobile;
