import React, { useState, useEffect } from "react";

//Components
import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";
import Footer from "./components/Footer";
import { initGA, PageViewGA } from "./GA";
import { initFP, PageViewFP } from "./FP";

//Sections
import HeaderSection from "./sections/Header_section";
import VideoSection from "./sections/Video_section";
import RedTextSection from "./sections/RedText_section";
import ProblemSection from "./sections/Problem_section";
import RecruiterSection from "./sections/Recruiter_section";

//Media
import BIJpegDesktop from "./resources/media/Bg_Mensfadder_Desktop.jpg";
import BIJpegMobile from "./resources/media/Bg_Mensfadder_Mobile.jpg";
import { device } from "./components/device";

require("dotenv").config();

function App() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [bg, setBg] = useState();

  const backgroundFixed = document.querySelector(".backgroundFixed");

  if (backgroundFixed) {
    backgroundFixed.style = `background-image: url(${bg})`;
  }

  useEffect(() => {
    if (innerWidth < device.laptop && bg !== BIJpegMobile) {
      setBg(BIJpegMobile);
    }
    if (innerWidth >= device.laptop && bg !== BIJpegDesktop) {
      setBg(BIJpegDesktop);
    }
  }, [innerWidth, bg]);

  useEffect(() => {
    //Google analytics
    initGA("UA-6544037-3");
    PageViewGA();
    //Facebook pixel
    initFP("218832521978531");
    PageViewFP();
  }, []);
  window.onresize = () => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
  };
  return (
    <>
      <div
        className="backgroundFixed"
        style={
          window.innerWidth > device.laptopL
            ? { backgroundSize: "100vw" }
            : { backgroundSize: "auto" }
        }
      />
      {innerWidth >= device.tablet && <Header />}
      {innerWidth < device.tablet && (
        <HeaderMobile imgMargin="auto 140px" innerWidth={innerWidth} />
      )}
      <HeaderSection innerWidth={innerWidth} />
      {/*<VideoSection innerWidth={innerWidth} innerHeight={innerHeight} />*/}
      <RedTextSection innerWidth={innerWidth} />
      <ProblemSection innerWidth={innerWidth} />
      <RecruiterSection innerWidth={innerWidth} />

      <Footer marginB="auto 140px" innerWidth={innerWidth} />
    </>
  );
}

export default App;
