import React, { useState } from "react";
import { device } from "../components/device";

//Components
import Section from "../components/Section";
import Image from "../components/Image";
import { P, H3 } from "../components/typo";

//Text animation
import Reveal from "react-reveal/Reveal";
import "../animate.css";

//Media
import papperSvg from "../resources/media/SVG/papperstuss_ny.svg";
import HandMedLadaJson from "../resources/media/json/HandMedLada.json";

const Problem_section = props => {
  const [timer, setTimer] = useState(false)

  setTimeout(() => {
    setTimer(true)
  },300)
  return (
    <Section id="problemSection">
      <div
        style={
          props.innerWidth >= device.tablet
            ? { width: "100%", display: "flex", marginTop: "20px" }
            : { width: "100%", marginTop: "40px" }
        }
      >
        {props.innerWidth < device.tablet && (
          <Image
            width="300px"
            margin="-35px auto 48px auto"
            right="0"
            imgMarginRight={props.innerWidth >= device.tablet ? "122px" : ""}
            src={papperSvg}
          />
        )}
        <div
          style={
            props.innerWidth >= device.tablet
              ? { width: "70%" }
              : { width: "100%" }
          }
        >
          <Reveal effect="animate">
            <H3
              textAlign="left"
              textAlignM="left"
              marginM="0px 0 0 0"
              text="HÄR ÄR PROBLEMET"
            />
            <P
              margin="0"
              maxWidth={props.innerWidth >= device.tablet ? "66%" : "100%"}
              marginTop="10px"
              marginTopM="24px"
              textAlign="left"
              textAlignM="left"
              text="Varje dag träffar vi unga personer som inte har råd med mensskydd. Försök tänka dig in i den situationen. Toalettpappret du använder istället för mensskydd skaver i trosorna och du oroar dig över fläckar på kläderna. Du frågar kompisarna om du får låna en binda, men du skäms över att hela tiden säga att du glömt."
            />
            <P
              margin="0"
              maxWidth={props.innerWidth >= device.tablet ? "66%" : "100%"}
              marginTop="10px"
              marginTopM="24px"
              textAlign="left"
              textAlignM="left"
              text="Rädslan för att blöda igenom påverkar sömnen och du får svårt att koncentrera dig i skolan. Du kan inte följa med och simma och du väljer att stanna hemma istället för att träffa dina vänner. För många unga i Göteborg är det just så verkligheten ser ut. Hjälp oss förändra det."
            />
          </Reveal>
        </div>
        {timer && props.innerWidth >= device.tablet && (
          <>
            <Image
              Py1="50"
              Py2="-30"
              position="absolute"
              imgWidth={props.innerWidth >= device.laptop ? "380px" :"350px"}
              left="56%"
              srcPng={papperSvg}
              src={papperSvg}
              margin={
                props.innerWidth >= device.tablet
                  ? "-2% auto 0 auto"
                  : "-480px 0% -100px 0px"
              }
            />
            <Image
              Py1="42"
              Py2="35"
              position="absolute"
              imgWidth={props.innerWidth >= device.laptop ? "380px" :"350px"}
              left="52%"
              srcPng={papperSvg}
              src={papperSvg}
              margin={
                props.innerWidth >= device.tablet
                  ? "-2% auto 0 auto"
                  : "-480px 0% -100px 0px"
              }
            />
            <Image
              Py1="56"
              Py2="20"
              position="absolute"
              imgWidth={props.innerWidth >= device.laptop ? "380px" :"350px"}
              left="60%"
              srcPng={papperSvg}
              src={papperSvg}
              margin={
                props.innerWidth >= device.tablet
                  ? "-2% auto 0 auto"
                  : "-480px 0% -100px 0px"
              }
            />
          </>
        )}
      </div>
      <div
        style={
          props.innerWidth >= device.tablet
            ? { width: "100%", display: "flex" }
            : { width: "100%" }
        }
      >
        <Image
          width={props.innerWidth >= device.tablet ? "1800px" : "400px"}
          margin={
            props.innerWidth >= device.tablet
              ? "0 0 -140px -290px"
              : "-10px 0 -20px -130px"
          }
          imgMarginRightM={props.innerWidth >= device.tablet ? "122px" : "0px"}
          animation={HandMedLadaJson}
          transform="rotate(5deg)"
        />
        <div style={props.innerWidth >= device.tablet ? props.innerWidth >= device.laptopL ? { width: "100%" } : props.innerWidth >= device.laptop && props.innerWidth < device.laptopL ? { width: "130%", marginTop: "0" } : { width: "130%", marginTop: "120px" } : { width: "100%" }}>
          <Reveal effect="animate">
            <H3
              textAlign="left"
              textAlignM="left"
              margin="0"
              width="100%"
              marginM="158px 0 0 0px"
              text="Hit går pengarna"
            />

            <P
              margin="0"
              maxWidth="664px"
              marginTop="10px"
              marginTopM="24px"
              marginLeft={props.innerWidth >= device.tablet ? "0px" : "0px"}
              textAlign="left"
              textAlignM="left"
              text="Pengarna du bidrar med som mensfadder använder vi dels för att dela ut gratis mensskydd, dels för att hjälpa unga människor ur fattigdom på längre sikt. Det kan till exempel innebära att vi hjälper dem att söka jobb eller ekonomiskt bistånd."
            />

            <P
              margin="23px"
              maxWidth="664px"
              marginTop="10px"
              marginTopM="24px"
              marginLeft={props.innerWidth >= device.tablet ? "0px" : "0px"}
              textAlign="left"
              textAlignM="left"
              text="Vi arbetar också med att stötta de många unga som känner sig utanför samhället, vilket är en vanlig konsekvens av fattigdom. Hos oss kan de möta andra som är i samma situation eller få möjlighet att träffa en kurator."
            />
          </Reveal>
        </div>
      </div>
    </Section>
  );
};

export default Problem_section;
