import React from "react";
import styled from "styled-components";
import { device } from "../device";
import { Event } from "../../GA";

const Btn = styled.div`
  width: ${props => props.widthButton || "326px"};
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  border-radius: 100px;
  margin: auto;
  padding: 0;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: ${props => props.color || "#fff"};
  background-color: ${props => props.BGcolor || "#26cc9e"};
  border: ${props => props.border};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    background-color: ${props => props.BGcolorM || "#23b089"};
  }
  @media ${device.laptopMedia} {
    width: ${props => props.widthButtonM || "302px"};
    color: ${props => props.colorM || "#fff"};
    border: ${props => props.borderM};
    background-color: ${props => props.BGcolorM || "#26cc9e"};
  }
`;

const ButtonBox = styled.div`
  height: ${props => props.heightButton || "100%"};
  font-size: 14px;
  border-radius: 100px;
  margin: ${props => props.margin || "auto"};
  margin-left: ${props => props.marginLeft || "auto"};
  padding-right: ${props => props.paddingRight};
  opacity: ${props => props.opacity};
  transition: opacity 0.2s linear;
  position: ${props => props.position};
  right: ${props => props.right};
  align-items: center;
  justify-content: center;
  display: flex;
  @media ${device.laptopMedia} {
    width: ${props => props.widthButtonM || "302px"};
    height: ${props => props.heightButtonM || "100%"};
    font-size: 14px;
    border-radius: 100px;
    margin: ${props => props.marginM};
    margin-left: ${props => props.marginLeft || "auto"};
    padding-right: ${props => props.paddingRightM};
    opacity: 1;
  }
`;


const Button = props => {
  const redirect = () => {
    window.location.replace(props.href);
  };

  return (
    <ButtonBox {...props} className={"button"}>
      <Btn
        {...props}
        rel="prefetch"
        ref={props.btnRef}
        className={props.class}
        onClick={() => {
          Event(props.GAcategory, props.GAaction, props.GAlable);
          redirect();
        }}
      >
        {props.text}
      </Btn>
    </ButtonBox>
  );
};

export default Button;
