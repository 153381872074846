import React from "react";
import styled from "styled-components";
import Image from "../../components/Image";
import svgLogo from "../../resources/media/logo.svg";
import Logo from "../../resources/media/new_logo.png";
import { device } from "../device";

const HeaderBody = styled.div`
  /* padding: ${
    window.innerWidth >= device.tablet
      ? "20px 140px 100px 140px"
      : "40px 23px 40px 23px"
  }; */
  z-index: 999;
  display: flex;
  position: relative;
  padding: ${
    window.innerWidth >= device.laptop ? "0 140px 0 140px" : "0 23px 0 23px"
  };

  top: 0;
  /* width: ${props => props.width || "100vw"}; */
  height: ${props => props.height || "100px"};
  background-color: ${props => props.BG};

  margin: auto;
  max-width: 1440px;
`;

const Link = styled.a`
  padding-top: 42px;
  font-size: 12px;
  width: 102px;
  color: #111111;
  letter-spacing: 0.36px;
  margin: ${props => props.margin || "0 0 0 auto"};
  font-weight: 700;
  text-decoration: none;
`;

const Header = props => {
  return (
    <HeaderBody {...props}>
      <Image
        objectFit="contain"
        margin={
          window.innerWidth >= device.laptop
            ? window.innerWidth >= device.laptopL ? "0 0 0 0" : "0 0 0 0"
            : window.innerWidth <= device.tablet
            ? "0 0 0 63px"
            : "0 0 0 23px"
        }
        height="75%"
        src={Logo}
      />
      <Link href="#videoSecction">MENSMANIFEST</Link>
      <Link
        href="#problemSection"
        margin={
          window.innerWidth >= device.laptop
            ? "0 0 0 30px"
            : "0 40px 0 30px"
        }
      >
        OM PROJEKTET
      </Link>
    </HeaderBody>
  );
};

export default Header;
