import React, { useEffect, useState } from "react";
import styled from "styled-components";

import plyBtn from '../../resources/media/SVG/Play_Button.svg'

const VideoPlayer = styled.video`
  height: 0px;
  background-color: black;
  width: 100%;
  max-height: 720px;
  display: block;
  margin: 0;
  border: 0;
`;


const PlayButton = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transform: ${props => props.transform};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  img {
    /* width: ${props => props.imgWidth}; */
    width: 80px;
  }
`;

const PlayImage = styled.img`
  position: relative;
  left: 0;
  display: block;
  width: ${props =>
    props.widthV
      ? "100%"
      : props.innerWidth > 1024
      ? "auto 140px auto 140px"
      : "auto 23px"};
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: "rgba( 17, 17, 17, 1)";
`;

const Video = props => {
  const video = document.querySelector("video");
  let videoImage = document.querySelector(".videoImage");
  const [isFullscreen, setIsFullscreen] = useState();
  const [videoHeight, setVideoHeight] = useState(
    videoImage ? videoImage.offsetHeight : "2"
  );
  const [videoWidth, setVideoWidth] = useState(
    videoImage ? videoImage.offsetWidth : "3"
  );

  useEffect(() => {
    if (videoImage) {
      setTimeout(() => {
        if (videoImage) {
          setVideoHeight(videoImage.offsetHeight);
          setVideoWidth(videoImage.offsetWidth);
        }
      }, 160);
    }
  }, [props.innerWidth, props.innerHeight, videoImage]);

  console.dir(videoImage);

  const onFullScreen = event => {
    if (event.srcElement.webkitDisplayingFullscreen) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
      document.getElementsByTagName("video")[0].pause();
    }
  };

  const playVideo = () => {
    if (!isFullscreen === true) {
      document.getElementsByTagName("video")[0].play();
      document.getElementsByTagName("video")[0].muted=false;
      document.getElementsByTagName("video")[0].webkitEnterFullscreen();
    }

    video.addEventListener("mozfullscreenchange", event => {
      onFullScreen(event);
    });
    video.addEventListener("webkitfullscreenchange", event => {
      onFullScreen(event);
    });
  };

  return (
    <>
      <PlayButton
        height={videoHeight}
        width={videoWidth}
        onClick={e => playVideo()}
      >
        <img src={plyBtn}  alt=""/>
      </PlayButton>
      <PlayImage
        className="videoImage"
        muted="false"
        {...props}
        src={props.videoImg}
        heightV={videoHeight}
        widthV={videoWidth}
        onClick={e => playVideo()}
      />
      <VideoPlayer
        heightV={videoHeight}
        widthV={videoWidth}
        onClick={() => {
          playVideo();
          // Event(props.GAcategory, props.GAaction, props.GAlable);
        }}
        {...props}
        muted
      >
        <source src={props.src} type="video/mp4" />
      </VideoPlayer>
    </>
  );
};

export default Video;
