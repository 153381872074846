import React, { useState, useEffect } from "react";
import { device } from "../components/device";

//Components
import Section from "../components/Section";
import Image from "../components/Image";
import Button from "../components/Button";
import { HeaderText, P } from "../components/typo";

//Text animation
import Reveal from "react-reveal/Reveal";
import "../animate.css";

//Media
import HandMedTamp from "../resources/media/SVG/tampong-NY.svg";
import HandMedKopp from "../resources/media/SVG/menskopp-hand.svg";
import LogoJson from "../resources/media/json/logo.json";

const Header_section = (props) => {
  const [width, setWidth] = useState(props.innerWidth);
  const [timer, setTimer] = useState(false);
  const [newPosition, setNewPosition] = useState(0);

  setTimeout(() => {
    setTimer(true);
  }, 300);

  useEffect(() => {
    setWidth(props.innerWidth);
    setNewPosition(-10);
  }, [props.innerWidth]);

  return (
    <Section
      positionBG="absolute"
      paddingM="40px 140px 0px 140px"
      padding="0px 23px 0 23px"
      position="relative"
      className="parallax"
      margin={width >= device.tablet ? "-100px 0 0 0" : ""}
      height="100vh"
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Reveal effect="animate">
          <Image
            width={width >= device.tablet ? "300px" : "240px"}
            // margin={
            //   width > device.laptop ? "160px auto 0  auto" : "auto"
            // }
            margin={
              width < device.tablet
                ? "-6vh auto 0  auto"
                : width > device.laptop && width < device.laptopL
                ? "-10vh auto 0  auto"
                : "-10vh auto 0  auto"
            }
            imgMarginRightM={width > device.laptop ? "0px" : "0px"}
            animation={LogoJson}
            IeHeight="300px"
          />
          <HeaderText
            textAlign="center"
            width="100%"
            margin={
              width > device.laptop ? "10px auto 0 auto" : "0vh auto 0 auto"
            }
            maxWidth="758px"
            text="HJÄLP EN UNG PERSON SOM INTE HAR RÅD MED MENSSKYDD"
          />
          <P
            padding={width > device.laptop ? "0" : "6px 6px 0 6px"}
            maxWidth="768px"
            width="100%"
            text="Varje dag möter vi på Göteborgs Stadsmission unga människor som inte har råd med mensskydd. Och så kan vi inte ha det. Två paket bindor eller tamponger kostar ungefär 70 kronor. Se till att en ung person kan köpa mensskydd varje månad."
          />
           <P
            padding={width > device.laptop ? "0" : "6px 6px 0 6px"}
            marginTop= "30px"
            maxWidth="768px"
            width="100%"
            text="Bli mensfadder genom att klicka på länken."
          />
          <Button
            GAcategory="TopBtn"
            GAaction="click"
            GAlable="TopBtn"
            widthButton="326px"
            widthButtonM="332px"
            BGcolor="#26cc9e"
            className="topButton"
            margin={
              width < device.tablet
                ? "3vh auto 0px auto"
                : width > device.laptop && width < device.laptopL
                ? "40px auto 0px auto"
                : "40px auto 0px auto"
            }
            href="https://www.stadsmissionen.org/bli-mensfadder/"
            text="BLI MENSFADDER FRÅN 70 KR/MÅN"
          />
        </Reveal>
      </div>
      {width >= device.laptopL && (
        <Image
          Py1={`${0}`}
          Py2={`${-40 - newPosition}`}
          position="absolute"
          imgWidth="460px"
          left="-70px"
          top="29%"
          srcPng={HandMedTamp}
          src={HandMedTamp}
          transformImg="rotate(18deg)"
          zIndex="-1"
        />
      )}
      {width >= device.laptop && width < device.laptopL && (
        <Image
          Py1={`${0}`}
          Py2={`${-40 - newPosition}`}
          position="absolute"
          imgWidth="340px"
          left="-70px"
          top="26%"
          srcPng={HandMedTamp}
          src={HandMedTamp}
          transformImg="rotate(18deg)"
          zIndex="-1"
        />
      )}
      {timer && width >= device.laptopL && (
        <Image
          Py1="10"
          Py2="40"
          position="absolute"
          display="block"
          imgWidth="280px"
          right="-40px"
          bottom="80px"
          zIndex="-1"
          transformImg="rotate(-20deg)"
          srcPng={HandMedKopp}
          src={HandMedKopp}
        />
      )}
      {width >= device.laptop && width < device.laptopL && (
        <Image
          Py1="10"
          Py2="40"
          position="absolute"
          display="block"
          imgWidth="220px"
          right="-90px"
          bottom="80px"
          zIndex="-1"
          transformImg="rotate(-20deg)"
          srcPng={HandMedKopp}
          src={HandMedKopp}
        />
      )}

      {/* Mobile images */}
      {width < device.tablet && (
        <Image
          Py1="0"
          Py2="-50"
          position="absolute"
          display="block"
          imgWidth="180px"
          width="65%"
          left="-115px"
          top="160px"
          srcPng={HandMedTamp}
          src={HandMedTamp}
          transform="rotate(20deg)"
          zIndex="-1"
        />
      )}
      {width >= device.tablet && width < device.laptop && (
        <Image
          Py1="25"
          Py2="0"
          position="absolute"
          display="block"
          imgWidth="300px"
          width="100%"
          left="-125px"
          top="160px"
          srcPng={HandMedTamp}
          src={HandMedTamp}
          transform="rotate(18deg)"
          zIndex="-1"
        />
      )}
      {width < device.tablet && (
        <Image
          Py1="0"
          Py2="20"
          position="absolute"
          display="block"
          imgWidth="140px"
          right="-45px"
          bottom="-100px"
          zIndex="-1"
          srcPng={HandMedKopp}
          src={HandMedKopp}
          transform="rotate(-20deg)"
        />
      )}
      {width >= device.tablet && width < device.laptop && (
        <Image
          Py1="0"
          Py2="20"
          position="absolute"
          display="block"
          imgWidth="200px"
          right="-60px"
          bottom="-80px"
          zIndex="-1"
          srcPng={HandMedKopp}
          src={HandMedKopp}
          transform="rotate(-20deg)"
        />
      )}
    </Section>
  );
};

export default Header_section;
