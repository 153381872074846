import React from "react";
import { device } from "../components/device";

//Components
import Section from "../components/Section";
import { H4 } from "../components/typo";

//Text animation
import Reveal from "react-reveal/Reveal";
import "../animate.css";

const RedText_section = props => {
  return (
    <Section
      overflow="hidden"
      BG="#ca180f"
      display={props.innerWidth >= device.laptop ? "flex" : "block"}
      // padding="58px 23px 58px 23px"
      paddingM={props.innerWidth >= device.laptopL ? "140px 268px 140px 268px" : "140px 140px 140px 140px"}
      padding={props.innerWidth >= device.tablet ? "100px 68px" : ""}
    >
      <Reveal effect="animate">
        <H4
          // textAlignM="left"
          style={{ textTransform: "uppercase" }}
          color="#fff"
          width={props.innerWidth > device.laptop ? "100%" : "100%"}
          text="“ När jag inte har råd att köpa bindor frågar jag mina kompisar om jag kan låna av dom. Varje dag frågar jag olika personer, för att inte vara jobbig.”"
        />
      </Reveal>
    </Section>
  );
};

export default RedText_section;
