const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768",
  laptop: "1024",
  laptopL: "1440",
  desktop: "2560px"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tabletMedia: `(min-width: ${size.tablet}px)`,
  tablet: size.tablet,
  laptopMedia: `(min-width: ${size.laptop}px)`,
  laptop: size.laptop,
  laptopLMedia: `(min-width: ${size.laptop}px)`,
  laptopL: size.laptopL,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};
