import React from "react";
import styled from "styled-components";
import { Parallax } from 'react-parallax';
import { device } from "../device";

const SectionBox = styled.div`

  /* width: 100vw; */
  overflow: ${props => props.overflow};
  height: ${props => props.height || "100%"};
  overflow-x: ${props => props.overflowX};
  /* overflow-y: scroll; */
  position: ${props => props.position};
  background-color: ${props => props.BG};
  padding: ${props => props.padding || "60px 23px"};
  /* background-image: url(${props => props.BI}); */
  margin: ${props => props.margin};

  @media ${device.laptopMedia} {
    display: ${props => props.display};
    padding-top: ${props => props.paddingTop || "100px"};
    padding: ${props => props.paddingM || "100px 140px"};
    background-color: ${props => props.BGM};
    height: ${props => props.height || "100%"};

    >* {
        margin: auto;
        max-width: 1440px;
    }
  }
`;

const SectionBoxParallx = styled.div`
  position: ${props => props.position};
  background-color: ${props => props.BGM || "rgba(0,0,0,0)"};
  /* background-color: ${props => props.BG}; */
  padding: ${props => props.padding || "40px 23px"};

  @media ${device.laptop} {
    display: ${props => props.display};
    padding: ${props => props.paddingM || "100px 140px"};
    padding-top: ${props => props.paddingTop || "100px"};
    height: ${props => props.height || "100%"};
  }
`;

const Section = (props) => {
  return (
    <>
      {!props.pBI && <SectionBox {...props}>{props.children}</SectionBox>}
      {props.pBI && (
        <Parallax {...props} bgImage={props.pBI} strength={200}>
          <SectionBoxParallx {...props}>{props.children}</SectionBoxParallx>
        </Parallax>
      )}
    </>
  );
}

export default Section;