import React from "react";
import styled from "styled-components";
import { device } from "./device";

const HeaderStyle = styled.h1`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  font-family: "SundayMorning";
  font-size: ${props => props.fontSize || "40px"};
  font-weight: ${props => props.fontWeight || "normal"};
  letter-spacing: -2.9px;
  width: ${props => props.width || "100%"};
  max-width: ${props => props.maxWidth};
  line-height: 1.15;
  letter-spacing: normal;
  margin: ${props => props.margin || "0 auto"};
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTop};
  text-align: ${props => props.textAlign || "center"};
  color: ${props => props.color || "#111"};
  z-index: 7;

  @media ${device.tabletMedia} {
    font-size: ${props => props.fontSizeT || "56px"};
  }

  @media ${device.laptopMedia} {
    font-size: ${props => props.fontSizeM || "68px"};
    margin: ${props => props.margin || "0 auto"};
    margin-left: ${props => props.marginLeftM};
    margin-top: ${props => props.marginTopM};
    width: ${props => props.widthM};
  }
`;

const H2Style = styled.h2`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  font-family: "SundayMorning";
  font-size: ${props => props.fontSize || "36px"};
  font-weight: ${props => props.fontWeight || "normal"};
  text-align: ${props => props.textAlign || "center"};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop || "0px"};
  margin-left: ${props => props.marginLeft};
  width: ${props => props.width || "100%"};
  color: ${props => props.color || "#111"};
  /* max-width: 80%; */

  @media ${device.tabletMedia} {
    font-size: ${props => props.fontSizeT || "50px"};
  }

  @media ${device.laptopMedia} {
    margin: ${props => props.marginM};
    text-align: ${props => props.textAlignM || "center"};
    font-size: ${props => props.fontSizeM || "60px"};
    margin-left: ${props => props.marginLeftM};
    max-width: ${props => props.maxWidthM};
    width: ${props => props.widthM};
    /* max-width: 80%; */
  }
`;

const H3Style = styled.h3`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  width: ${props => props.width || "100%"};
  position: ${props => props.position};
  font-family: 'SundayMorning', sans-serif;
  font-size: ${props => props.fontSize || "34px"};
  font-weight: ${props => props.fontWeight || "normal"};
  text-align: ${props => props.textAlign || "center"};
  color: ${props => props.color || "#111"};
  margin: ${props => props.margin || "0"};
  margin-bottom: 0;
  /* width: ${props => props.width || "100%"};
  max-width: 80%; */
  @media ${device.tabletMedia} {
      font-size: ${props => props.fontSizeT || "40px"};
  }
  
  @media ${device.laptopMedia} {
    text-align: ${props => props.textAlignM || "center"};
    font-size: ${props => props.fontSizeM || "50px"};
    margin: ${props => props.marginM || "0"};
    /* width: ${props => props.width}; */
  }
`;

const H4Style = styled.h4`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  width: ${props => props.width || "100%"};
  position: ${props => props.position};
  font-family: 'SundayMorning', sans-serif;
  font-size: ${props => props.fontSize || "24px"};
  font-weight: ${props => props.fontWeight || "normal"};
  text-align: ${props => props.textAlign || "center"};
  color: ${props => props.color || "#111"};
  margin: ${props => props.margin || "0"};
  margin-bottom: 0;
  /* width: ${props => props.width || "100%"};
  max-width: 80%; */
  @media ${device.tabletMedia} {
      font-size: ${props => props.fontSizeT || "36px"};
  }
  
  @media ${device.laptopMedia} {
    text-align: ${props => props.textAlignM || "center"};
    font-size: ${props => props.fontSizeM || "42px"};
    margin: ${props => props.marginM || "0"};
    /* width: ${props => props.width}; */
  }
`;

const PStyle = styled.p`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  font-family: "Montserrat", sans-serif;
  font-size: ${props => props.fontSize || "15px"};
  font-weight: ${props => props.fontWeight || "normal"};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin || "0 auto"};
  margin-left: ${props => props.marginLeft};
  padding-right: ${props => props.paddingRight};
  margin-top: ${props => props.marginTop};
  text-align: ${props => props.textAlign || "center"};
  letter-spacing: ${props => props.letterSpacing || "0.1px"};
  line-height: 1.56;
  color: ${props => props.color || "#111"};

  @media ${device.tabletMedia} {
    font-size: ${props => props.fontSizeT || "15px"};
  }

  @media ${device.laptopMedia} {
    margin-top: ${props => props.marginTopM || "30px"};
    font-size: ${props => props.fontSizeM || "16px"};
    width: ${props => props.widthM};
    text-align: ${props => props.textAlignM || "center"};
    margin-left: ${props => props.marginLeftM};
  }
`;

const AStyle = styled.a`
  top: ${props => props.top};
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  font-family: "Montserrat", sans-serif;
  font-size: ${props => props.fontSize || "15px"};
  font-weight: ${props => props.fontWeight || "normal"};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin || "0 auto"};
  margin-left: ${props => props.marginLeft};
  padding-right: ${props => props.paddingRight};
  margin-top: ${props => props.marginTop};
  text-align: ${props => props.textAlign || "center"};
  letter-spacing: ${props => props.letterSpacing || "0.1px"};
  line-height: 1.56;
  color: ${props => props.color || "#111"};
  text-decoration: none;

  @media ${device.tabletMedia} {
    font-size: ${props => props.fontSizeT || "15px"};
  }

  @media ${device.laptopMedia} {
    margin-top: ${props => props.marginTopM || "30px"};
    font-size: ${props => props.fontSizeM || "16px"};
    width: ${props => props.widthM};
    text-align: ${props => props.textAlignM || "center"};
    margin-left: ${props => props.marginLeftM};
  }
`;

export const HeaderText = props => {
  return <HeaderStyle {...props}>{props.text}</HeaderStyle>;
};

export const H2 = props => {
  return <H2Style {...props}>{props.text}</H2Style>;
};

export const H3 = props => {
  return <H3Style {...props}>{props.text}</H3Style>;
};
export const H4 = props => {
  return <H4Style {...props}>{props.text}</H4Style>;
};

export const P = props => {
  return <PStyle {...props}>{props.text}</PStyle>;
};
export const A = props => {
  return (
    <AStyle href={props.link} {...props}>
      {props.text}
    </AStyle>
  );
};
