import React from "react";
import styled from "styled-components";
import { device } from "../device";
import { Parallax } from "react-scroll-parallax";

import Lottie from "react-lottie";

const ImgBox = styled.picture`
  display: ${props => props.display || "flex"};
  width: ${props => props.width};
  margin: ${props => props.margin};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  margin-top: ${props => props.marginTopM};
  position: ${props => props.position};
  bottom: ${props => props.bottom};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  left: ${props => props.left};
  z-index: ${props => props.zIndex};
  transform: ${props => props.transform};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .parallax-outer {
       height: ${props => props.IeHeight}
     }
}
`;

const Img = styled.img`
  transform: translateZ(-10px);
  width: ${props => props.imgWidth || ""};
  height: ${props => props.imgheight || ""};
  margin: ${props => props.imgMargin || "auto"};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.imgMarginRight};
  margin-bottom: ${props => props.marginBottom};
  padding-right: ${props => props.paddingRight};
  object-fit: ${props => props.objectFit || "cover"};
  transform: ${props => props.transformImg};
  filter: ${props => props.filter};
  position: relative;

  @media ${device.laptopMedia} {
    width: ${props => props.imgWidthM || ""};
    margin-left: ${props => props.marginLeftM};
  }
`;

const Image = props => {
  const defaultOptions = {
    animationData: props.animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <ImgBox className={"lottie " + props.name} {...props}>
      {props.animation && (
        <Parallax
          className="custom-class"
          y={[props.Py1 ? props.Py1 : "0", props.Py2 ? props.Py2 : "0"]}
          tagOuter="figure"
        >
          <Lottie options={defaultOptions} />
        </Parallax>
      )}
      {/* <source srcSet={props.src} type="image/webp" alt="" /> */}
      {/* <source srcSet={props.srcPng} type="image/jpeg" alt="" /> */}
      {!props.animation && props.Py1 && (
        <Parallax
          className="custom-class"
          y={[props.Py1 ? props.Py1 : "0", props.Py2 ? props.Py2 : "0"]}
          // tagOuter="figure"
        >
          <Img {...props} src={props.src} alt={props.alt + " img"} />
        </Parallax>
      )}
      {!props.animation && !props.Py1 && (
        <Img {...props} src={props.src} alt={props.alt + " img"} />
      )}
    </ImgBox>
  );
};

export default Image;
