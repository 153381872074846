import React from "react";
import styled from "styled-components";

//Components
import Section from "../components/Section";
import Button from "../components/Button";
import { P, H2 } from "../components/typo";

//Text animation
import Reveal from "react-reveal/Reveal";
import "../animate.css";
import { device } from "../components/device";

const Span = styled.span`
  /* margin: 0 -2% 0 -1%; */
`;

const Recruiter_section = (props) => {
  return (
    <Section
      BG="#ca180f"
      overflow="hidden"
      padding={
        props.innerWidth >= device.tablet && props.innerWidth < device.laptop
          ? "80px 40px"
          : ""
      }
    >
      <Reveal effect="animate">
        <H2
          // fontSizeM="60px"
          maxWidthM="472px"
          color="white"
          margin="0 auto 0 auto"
          text={["BLI MENSFADDER FÖR 70:- PER M", <Span>ÅN</Span>, "AD"]}
        />
        <P
          color="white"
          width="100%"
          widthM="768px"
          marginTop="24px"
          text="Varje dag möter vi på Göteborgs Stadsmission unga människor som inte har råd med mensskydd. Och så kan vi inte ha det. Två paket bindor eller tamponger kostar ungefär 70 kronor. Se till att en ung person kan köpa mensskydd varje månad."
        />
         <P
          color="white"
          width="100%"
          widthM="768px"
          marginTop="16px"
          text="Bli mensfadder genom att klicka på länken."
        />
      </Reveal>
      <Button
        BGcolor="#26cc9e"
        GAcategory="BottomBtn"
        GAaction="click"
        GAlable="BottomBtn"
        margin="40px auto 0 auto"
        marginM="40px auto 0 auto"
        text="BLI MENSFADDER FRÅN 70 KR/MÅN"
        href="https://www.stadsmissionen.org/bli-mensfadder/"
      />
    </Section>
  );
};

export default Recruiter_section;
